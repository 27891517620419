import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { createBlogCategoryUrl } from '../../utils/blog';
import { borders, colors, fonts } from '../../styles/theme';
import { BlogPostCategoriesInfoFragment, Maybe } from '../../../gatsby-graphql';

interface IBlogPostCategoriesListProps {
  categories?: Maybe<BlogPostCategoriesInfoFragment>[] | null;
  locale: string;
}

const BlogPostCategoriesList: React.FC<IBlogPostCategoriesListProps> = ({
  categories,
  locale,
}) => (
  <CategoriesContainer>
    {categories?.map((category) => (
      <Link
        to={createBlogCategoryUrl(category?.slug as string, locale)}
        key={String(category?.slug)}
      >
        <img
          alt={String(category?.title)}
          src={String(category?.logo?.file?.url)}
        />
        <span>{category?.title}</span>
      </Link>
    ))}
  </CategoriesContainer>
);

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    color: ${colors.text.grey};
    text-decoration: none;
    font-family: ${fonts.sansSerif};
    font-size: 16px;
    line-height: 16px;
    border: 1px solid;
    padding: 0.25rem 0.5rem;
    ${borders.radius};
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }
    img {
      width: 12px;
      margin: 0 0.25rem 0 0;
    }
  }
`;

export default BlogPostCategoriesList;
