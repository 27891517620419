import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { BaseContainer } from '../components/baseComponents';
import BlogPostGrid from '../components/BlogPostsGrid';
import { BlogPageQuery } from '../../gatsby-graphql';
import BlogHero from '../components/BlogHero';
import PageBanner from '../components/PageBanner';
import { IPage } from '../types';

const Blog: React.FC<IPage<BlogPageQuery>> = ({
  data: {
    posts,
    lastBlogPost,
    navigationItem,
    categories,
    categoriesTitle,
    lastBlogLabel,
    siteNavigation,
    footerSocialLinkLabel,
  },
  pageContext: { locale },
  location: { pathname },
}) => {
  const pageName = String(navigationItem?.name);
  return (
    <Layout
      pagePathName={pathname}
      pageTitle={pageName}
      pageDescription={navigationItem?.seo?.description as string}
      pageImage={navigationItem?.seo?.image?.file?.url as string}
      lang={locale}
      section={navigationItem}
      siteNavigation={siteNavigation}
      footerSocialLabel={footerSocialLinkLabel?.value as string}
    >
      <PageBanner copy={navigationItem?.bannerCopy?.json} />
      <BaseContainer>
        <BlogHero
          {...{ locale }}
          lastBlogPost={lastBlogPost.nodes[0]}
          categories={categories.nodes}
          labels={{
            categoriesTitle: String(categoriesTitle?.value),
            latestBlog: String(lastBlogLabel?.value),
          }}
        />
        <BlogPostGrid posts={posts.nodes} locale={locale} />
      </BaseContainer>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogPage($locale: String!, $sectionSlug: String!) {
    ...PageInfoFragment
    # Navigation Item Info
    navigationItem: contentfulNavigationItem(
      slug: { eq: $sectionSlug }
      node_locale: { eq: $locale }
    ) {
      ...NavigationSectionInfo
      bannerCopy {
        json
      }
    }
    # Labels
    # ---Categories Title Label
    categoriesTitle: contentfulLabel(
      slug: { eq: "categories-menu-title" }
      node_locale: { eq: $locale }
    ) {
      value
    }
    # ---Last Blog Label
    lastBlogLabel: contentfulLabel(
      slug: { eq: "last-blog-label" }
      node_locale: { eq: $locale }
    ) {
      value
    }
    # Last Blog Post
    lastBlogPost: allContentfulBlogPost(
      limit: 1
      sort: { order: DESC, fields: createdAt }
      filter: {
        node_locale: { eq: $locale }
        avoidProductionRender: { ne: true }
      }
    ) {
      nodes {
        ...LastBlogPostCard
      }
    }
    # Blog Posts List
    posts: allContentfulBlogPost(
      sort: { order: DESC, fields: createdAt }
      skip: 1
      filter: {
        node_locale: { eq: $locale }
        avoidProductionRender: { ne: true }
      }
    ) {
      totalCount
      nodes {
        ...BlogPostCard
      }
    }
    # Categories List
    categories: allContentfulCategory(
      sort: { order: [ASC], fields: [title] }
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        ...CategoryInfo
      }
    }
  }
`;
