import * as React from 'react';
import { FluidObject } from 'gatsby-image';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Date from '../Date';
import {
  BaseBlogPostDescription,
  BaseBlogPostImage,
  BaseBlogPostTitle,
} from '../baseComponents';
import { createBlogPostUrl } from '../../utils/blog';
import { colors, fonts } from '../../styles/theme';
import { BlogPostCardFragment } from '../../../gatsby-graphql';
import BlogPostCategoriesList from '../BlogPostCategoriesList';

interface IBlogPostCard extends BlogPostCardFragment {
  locale: string;
}

const BlogPostCard: React.FC<IBlogPostCard> = (props) => {
  return (
    <PostContainer>
      <Link to={createBlogPostUrl(props.slug as string, props.locale)}>
        <StyledGatsbyImage
          fluid={props.heroImage?.fluid as FluidObject}
          alt={props.heroImage?.description as string}
        />
        <InfoContainer>
          <StyledDate date={props.createdAt} locale={props.locale} />
          <Title>{props.title as string}</Title>
          <Description>
            {String(props.shortDescription?.shortDescription).substr(0, 140)}
          </Description>
        </InfoContainer>
      </Link>
      <BlogPostCategoriesList
        categories={props.categories}
        locale={props.locale}
      />
    </PostContainer>
  );
};

const PostContainer = styled.div`
  & > a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledGatsbyImage = styled(BaseBlogPostImage)`
  width: 100%;
`;

const InfoContainer = styled.div`
  padding: 20px 0 0;
`;

const Title = styled(BaseBlogPostTitle)``;

const Description = styled(BaseBlogPostDescription)``;

const StyledDate = styled(Date)`
  font-family: ${fonts.sansSerif};
  font-size: 14px;
  font-weight: 400;
  color: ${colors.text.grey};
  text-transform: capitalize;
`;

export default BlogPostCard;
