import React from 'react';
import styled from 'styled-components';
import BlogPostCard from '../BlogPostCard';
import CategoriesInfoList from '../CategoriesInfoList';
import { borders, fonts, mq, spacing } from '../../styles/theme';
import {
  CategoryInfoFragment,
  LastBlogPostCardFragment,
} from '../../../gatsby-graphql';

interface IBlogHeroProps {
  categories: CategoryInfoFragment[];
  lastBlogPost: LastBlogPostCardFragment;
  locale: string;
  labels: {
    categoriesTitle: string;
    latestBlog: string;
  };
}

const BlogHero: React.FC<IBlogHeroProps> = ({
  categories,
  labels,
  lastBlogPost,
  locale,
}) => {
  return (
    <Container>
      <div>
        <SectionTitle>{labels.categoriesTitle}</SectionTitle>
        <CategoriesInfoList {...{ categories }} locale={locale} />
      </div>
      <div>
        <SectionTitle>{labels.latestBlog}</SectionTitle>
        <BlogPostCard {...lastBlogPost} locale={locale} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding-top: ${spacing.sectionPageContent.top.base};
  display: grid;
  row-gap: 2rem;
  ${borders.spacer};
  padding-bottom: 2rem;
  ${mq.tablet} {
    column-gap: 45px;
    grid-template-columns: 1fr 3fr;
  }
  ${mq.desktop} {
    padding-top: ${spacing.sectionPageContent.top.desktop};
  }
`;

const SectionTitle = styled.h1`
  font-family: ${fonts.sansSerif};
  font-size: 1.5rem;
  text-transform: capitalize;
`;

export default React.memo(BlogHero);
