import * as React from 'react';
import styled from 'styled-components';
import BlogPostCard from '../BlogPostCard';
import { mq, spacing } from '../../styles/theme';
import { BlogPostCardFragment } from '../../../gatsby-graphql';

interface IBlogPostGridProps {
  posts: BlogPostCardFragment[];
  locale: string;
}

const BlogPostGrid: React.FC<IBlogPostGridProps> = ({ posts, locale }) => {
  return (
    <Container>
      {posts.map((post) => (
        <BlogPostCard key={String(post.slug)} {...post} locale={locale} />
      ))}
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: ${spacing.grid.row.gap};
  column-gap: ${spacing.grid.column.gap};
  padding: 2rem 0;
  ${mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default BlogPostGrid;
