import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { CategoryInfoFragment } from '../../../gatsby-graphql';
import { borders, fonts, mq } from '../../styles/theme';
import { createBlogCategoryUrl } from '../../utils/blog';

interface ICategoriesInfoListProps {
  categories: CategoryInfoFragment[];
  locale: string;
}

const CategoriesInfoList: React.FC<ICategoriesInfoListProps> = ({
  categories,
  locale,
}) => (
  <Container>
    {categories.map((category) => (
      <StyledLink
        to={createBlogCategoryUrl(category.slug as string, locale)}
        key={String(category.slug)}
      >
        <img
          src={String(category.logo?.file?.url)}
          alt={String(category.logo?.description)}
        />
        <span>{category.title}</span>
      </StyledLink>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  ${borders.spacer};
  ${mq.tablet} {
    padding-bottom: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: none;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
  margin-right: 0.75rem;
  img {
    width: 24px;
    margin: 0 0.5rem 0 0;
    ${mq.tablet} {
      margin: 0 1rem 0 0;
    }
  }
  span {
    font-family: ${fonts.sansSerif};
  }
  ${mq.tablet} {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
`;

export default CategoriesInfoList;
